"use client";

import SearchResults from "@components/searchResults";
import Logo from "@features/iTICKET-logo";
import { BasketSummary, Edgeconfig } from "@types";
import { basketSummaryFetcher, fetcher } from "@utils/fetchers";
import { getCookieValue, joinClassNames } from "@utils/helpers";
import {
  useHydrated,
  useLatestCookieValue,
  useSupabaseSession,
  useWindowSize,
} from "@utils/hooks";
import { AnimatePresence, motion } from "framer-motion";
import dynamic from "next/dynamic";
import Link from "next/link";
import useSWR, { mutate } from "swr";
import Cart from "./components/cart";
import GenreNavMenu from "./components/genreNavMenu";
import MobileMenu from "./components/mobileMenu";
import { usePathname, useRouter } from "next/navigation";
import { CheckoutDetails } from "@features/checkout/types";
import CartOpenStateWrapper from "./components/cartOpenStateWrapper";
import { BellAlertIcon } from "@heroicons/react/20/solid";
import { BASE_URL, COUNTRY_CODE } from "@utils/constants";
import axios from "axios";
import { useNotificationStore } from "@store/notificationStore";
const AccountPopover = dynamic(
  () =>
    import("@components/popovers/accountPopover").then(
      (module) => module.default
    ),
  { ssr: false }
);

const BANNER_SCROLL_VELOCITY = 0.105;

interface Props {
  edgeConfig: Edgeconfig | undefined;
  isDraftMode: boolean;
}

export default function Navbar({ edgeConfig, isDraftMode }: Props) {
  const router = useRouter();
  const windowsize = useWindowSize();
  const basketKey = useLatestCookieValue("ASP.NET_SessionId");
  const hyrdated = useHydrated();
  const clarityUserId = useLatestCookieValue("_clck")?.split("%")[0];
  const { setFailure, setSuccess } = useNotificationStore((state) => state);
  const session = useSupabaseSession();
  const pathname = usePathname();
  const { data: basket } = useSWR<BasketSummary>(
    basketKey ? `basket/${basketKey}/summary` : null,
    basketSummaryFetcher,
    {
      refreshInterval: 30 * 1000,
      refreshWhenHidden: true,
    }
  );

  const { data: checkoutDetails } = useSWR<CheckoutDetails>(
    basketKey &&
      (pathname === "/cart/checkout/payment" ||
        pathname === "/cart" ||
        pathname.includes("status"))
      ? `basket/${basketKey}/checkout`
      : null,
    fetcher
  );

  return (
    <div
      data-testid="navbar"
      className={joinClassNames(
        "z-50 w-full",
        edgeConfig?.banner.enabled ? "h-[138px]" : "h-[5.5rem]"
      )}
    >
      <header className="fixed top-0 z-50 w-screen bg-black">
        {hyrdated ? (
          <AnimatePresence>
            {isDraftMode ? (
              <motion.section
                initial="hidden"
                animate="show"
                exit="hidden"
                variants={{
                  hidden: {
                    height: 0,
                    transition: {
                      duration: 0.125,
                    },
                  },
                  show:
                    windowsize.width && windowsize.width < 640
                      ? { height: "106px" }
                      : { height: "50px" },
                }}
                transition={{ duration: 0.25 }}
                className="w-full bg-yellow-200"
              >
                <motion.div
                  initial="hidden"
                  animate="show"
                  exit="hidden"
                  variants={{
                    hidden: { opacity: 0, transition: { duration: 0.125 } },
                    show: { opacity: 1 },
                  }}
                  transition={{ duration: 0.25 }}
                  className="mx-auto flex flex-col gap-2 sm:flex-row max-w-7xl items-center justify-between p-2 lg:px-8"
                >
                  <p className="text-yellow-800">
                    You are viewing this event in draft mode. Do not use this
                    link publicly. Tickets cannot be sold to an event in draft
                    mode.
                  </p>
                  <button
                    className="flex w-full sm:w-fit gap-2 items-center justify-center disabled:bg-yellow-200 disabled:cursor-not-allowed rounded-md border border-transparent bg-yellow-500 text-yellow-800 px-2 py-1 text-base font-medium shadow-sm focus:outline-none focus:ring-2 focus:ring-yellow-500"
                    onClick={async () => {
                      const res = await axios.get("/api/disable-draft");

                      if (res.status !== 200) return;

                      const basketKey = getCookieValue("ASP.NET_SessionId");

                      if (!basketKey) {
                        setSuccess(`Draft Mode Disabled`);
                        router.refresh();
                        return;
                      }

                      const response = await axios.delete(
                        `${BASE_URL}/legacy/${COUNTRY_CODE}/shop/basket/${basketKey}`,
                        {
                          headers: {
                            "itk-website-uid":
                              process.env.NEXT_PUBLIC_ITK_WEBSITE_UID,
                          },
                        }
                      );

                      if (response.status >= 200 && response.status < 300) {
                        setSuccess(`Draft Mode Disabled`);
                        await axios.delete("/api/basket");
                        await mutate(`basket/${basketKey}`);
                        await mutate(`basket/${basketKey}/summary`);
                        await mutate(`basket/${basketKey}/checkout`);
                        await mutate(
                          (key) =>
                            typeof key === "string" &&
                            key.startsWith("events/"),
                          undefined,
                          {
                            revalidate: true,
                          }
                        );
                      } else if (
                        response.status >= 400 &&
                        response.status < 500
                      ) {
                        setFailure(response.data.message);
                      } else {
                        setFailure("Something went wrong.", true);
                      }

                      router.refresh();
                    }}
                  >
                    Disable Draft
                  </button>
                </motion.div>
              </motion.section>
            ) : edgeConfig?.banner.enabled ? (
              <section
                className={joinClassNames(
                  "w-full",
                  edgeConfig.banner.type === "green"
                    ? "animate-notice-border-green"
                    : edgeConfig.banner.type === "yellow"
                    ? "animate-notice-border-yellow"
                    : "animate-notice-border-red"
                )}
              >
                <a
                  href={`${edgeConfig.banner.link}${
                    clarityUserId ? `?entry.2100692719=${clarityUserId}` : ""
                  }`}
                  target="_blank"
                  className={joinClassNames(
                    "flex items-center gap-4 backdrop-blur-3xl text-sm font-medium w-full h-12 whitespace-nowrap",
                    edgeConfig.banner.type === "green"
                      ? "text-emerald-800"
                      : edgeConfig.banner.type === "yellow"
                      ? "text-yellow-800"
                      : "text-red-300 bg-black/40"
                  )}
                >
                  <p
                    style={{
                      animationDuration: `${
                        edgeConfig.banner.text.length * BANNER_SCROLL_VELOCITY
                      }s`,
                    }}
                    className="flex items-center gap-4 animate-marquee w-fit will-change-transform"
                  >
                    {edgeConfig.banner.text}
                    <BellAlertIcon className="inline h-5 w-5" />
                  </p>
                  <p
                    style={{
                      animationDuration: `${
                        edgeConfig.banner.text.length * BANNER_SCROLL_VELOCITY
                      }s`,
                    }}
                    className="flex items-center gap-4 animate-marquee w-fit will-change-transform"
                  >
                    {edgeConfig.banner.text}
                    <BellAlertIcon className="inline h-5 w-5" />
                  </p>
                  <p
                    style={{
                      animationDuration: `${
                        edgeConfig.banner.text.length * BANNER_SCROLL_VELOCITY
                      }s`,
                    }}
                    className="flex items-center gap-4 animate-marquee w-fit will-change-transform"
                  >
                    {edgeConfig.banner.text}
                    <BellAlertIcon className="inline h-5 w-5" />
                  </p>
                  <p
                    style={{
                      animationDuration: `${
                        edgeConfig.banner.text.length * BANNER_SCROLL_VELOCITY
                      }s`,
                    }}
                    className="flex items-center gap-4 animate-marquee w-fit will-change-transform"
                  >
                    {edgeConfig.banner.text}
                    <BellAlertIcon className="inline h-5 w-5" />
                  </p>
                  <p
                    style={{
                      animationDuration: `${
                        edgeConfig.banner.text.length * BANNER_SCROLL_VELOCITY
                      }s`,
                    }}
                    className="flex items-center gap-4 animate-marquee w-fit will-change-transform"
                  >
                    {edgeConfig.banner.text}
                    <BellAlertIcon className="inline h-5 w-5" />
                  </p>
                  <p
                    style={{
                      animationDuration: `${
                        edgeConfig.banner.text.length * BANNER_SCROLL_VELOCITY
                      }s`,
                    }}
                    className="flex items-center gap-4 animate-marquee w-fit will-change-transform"
                  >
                    {edgeConfig.banner.text}
                    <BellAlertIcon className="inline h-5 w-5" />
                  </p>
                  <p
                    style={{
                      animationDuration: `${
                        edgeConfig.banner.text.length * BANNER_SCROLL_VELOCITY
                      }s`,
                    }}
                    className="flex items-center gap-4 animate-marquee w-fit will-change-transform"
                  >
                    {edgeConfig.banner.text}
                    <BellAlertIcon className="inline h-5 w-5" />
                  </p>
                  <p
                    style={{
                      animationDuration: `${
                        edgeConfig.banner.text.length * BANNER_SCROLL_VELOCITY
                      }s`,
                    }}
                    className="flex items-center gap-4 animate-marquee w-fit will-change-transform"
                  >
                    {edgeConfig.banner.text}
                    <BellAlertIcon className="inline h-5 w-5" />
                  </p>
                </a>
              </section>
            ) : (
              <></>
            )}
          </AnimatePresence>
        ) : (
          <></>
        )}

        <nav
          className="mx-auto flex max-w-7xl items-center justify-between p-3 lg:px-8"
          aria-label="Global"
        >
          <div className="flex items-center gap-8 lg:flex-1">
            <Link href="/" className="-m-1.5 p-1.5">
              <span className="sr-only">iTICKET</span>
              <Logo size="20" />
            </Link>
            <div className="hidden lg:block">
              <GenreNavMenu />
            </div>
          </div>
          <div className="flex items-center">
            <div className="hidden lg:flex">
              <SearchResults />
            </div>
            <div className="hidden lg:flex lg:flex-1 lg:justify-end lg:ml-4">
              {session ? (
                <AccountPopover />
              ) : (
                <Link
                  href="/account/sign-in"
                  className="text-sm font-semibold leading-6 text-white"
                >
                  Log in/Sign up
                </Link>
              )}
            </div>
            <AnimatePresence>
              {basket && basket.Quantity && (
                <motion.div
                  data-testid={`cart`}
                  initial="hidden"
                  animate={basket.Quantity > 0 ? "show" : "hidden"}
                  exit="hidden"
                  variants={{
                    hidden: { width: 0, transition: { duration: 0.125 } },
                    show: { width: "auto" },
                  }}
                  transition={{ duration: 0.25 }}
                  className={joinClassNames(
                    "overflow-hidden",
                    basket.Quantity > 0 ? "" : "hidden"
                  )}
                >
                  <motion.div
                    initial="hidden"
                    animate={basket.Quantity > 0 ? "show" : "hidden"}
                    exit="hidden"
                    variants={{
                      hidden: {
                        opacity: 0,
                        x: window.innerWidth >= 1024 ? "-6rem" : "6rem",
                        transition: { duration: 0.125 },
                      },
                      show: { opacity: 1, x: 0 },
                    }}
                    transition={{ duration: 0.25 }}
                  >
                    <CartOpenStateWrapper>
                      {(open, setOpen) => (
                        <Cart
                          key={basket.Ttl}
                          open={open}
                          setOpen={setOpen}
                          basket={basket}
                          isPended={checkoutDetails?.IsPended ?? false}
                        />
                      )}
                    </CartOpenStateWrapper>
                  </motion.div>
                </motion.div>
              )}
            </AnimatePresence>
            <div className="flex ml-10 lg:ml-0 lg:hidden">
              <MobileMenu />
            </div>
          </div>
        </nav>
      </header>
    </div>
  );
}
