"use client";

import * as Form from "@radix-ui/react-form";
import { Account, Country } from "@types";
import { FormEvent, useState } from "react";
import { CountryCode } from "libphonenumber-js/core";
import PhoneInputWithCountrySelect from "react-phone-number-input";
import "react-phone-number-input/style.css";
import {
  ChevronDownIcon,
  ExclamationCircleIcon,
} from "@heroicons/react/24/outline";
import { range } from "@utils/helpers";
import axios, { AxiosError } from "axios";
import { useNotificationStore } from "@store/notificationStore";
import LoadingSpinner from "@components/loadingSpinner";
import { authorizedFetcher } from "@utils/fetchers";
import { useHydrated, useSupabaseSession } from "@utils/hooks";
import useSWR from "swr";
import {
  ExclamationTriangleIcon,
  FaceFrownIcon,
} from "@heroicons/react/24/solid";
import {
  BASE_URL,
  COUNTRY_CODE,
  SUPABASE_ANON_KEY,
  SUPABASE_URL,
} from "@utils/constants";
import { createBrowserClient } from "@supabase/ssr";
import PhoneNumberTooltip from "./phoneNumberTooltip";
import { parsePhoneNumber } from "react-phone-number-input";

interface Props {
  countries: Country[];
  defaultCountryCode: CountryCode;
  accountData: Account;
}

export default function ProfileForm({
  countries,
  defaultCountryCode,
  accountData,
}: Props) {
  const supabase = createBrowserClient(SUPABASE_URL!, SUPABASE_ANON_KEY!);
  const hydrated = useHydrated();
  const { setSuccess, setFailure } = useNotificationStore((state) => ({
    setSuccess: state.setSuccess,
    setFailure: state.setFailure,
  }));
  const [error, setError] = useState({
    user_exists: false,
    missing_phone_numbers: false,
    other: false,
  });
  const [loading, setLoading] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState<Country>(
    countries.find((c) => c.code === accountData.country) ?? countries[0]
  );
  const [phoneNumbers, setPhoneNumbers] = useState<{
    mobile?: string;
    landline?: string;
  }>({
    mobile: accountData.mobile,
    landline: accountData.phone,
  });
  const [showOldPhone, setShowOldPhone] = useState<{
    mobile: boolean;
    phone: boolean;
  }>({
    mobile: !!accountData.oldMobile,
    phone: !!accountData.oldPhone,
  });

  const session = useSupabaseSession();
  const {
    data: account,
    mutate: mutateAccount,
    isLoading: accountLoading,
  } = useSWR<Account>(
    session ? ["account", session.access_token] : null,
    authorizedFetcher,
    {
      fallback: accountData,
    }
  );

  const yearsArray = range(
    new Date().getFullYear() - 10,
    new Date().getFullYear() - 100,
    -1
  );

  async function handleSubmit(event: FormEvent<HTMLFormElement>) {
    setLoading(true);
    event.preventDefault();
    const {
      first_name,
      last_name,
      email,
      country,
      region,
      postcode,
      birth_year,
      gender,
    } = Object.fromEntries(new FormData(event.currentTarget));

    if (!phoneNumbers.mobile && !phoneNumbers.landline) {
      setLoading(false);
      setError((prev) => ({ ...prev, missing_phone_numbers: true }));
      setFailure(
        "Oops! We couldn't update your account details. At least one mobile or landline number is required."
      );
      return;
    }

    const {
      data: { session },
    } = await supabase.auth.getSession();

    const payload = {
      firstname: account?.firstname !== first_name ? first_name : undefined,
      surname: account?.surname !== last_name ? last_name : undefined,
      email: account?.email !== email ? email : undefined,
      mobile:
        account?.mobile !== phoneNumbers.mobile
          ? phoneNumbers.mobile
          : undefined,
      oldMobile: null,
      phone:
        account?.phone !== phoneNumbers.landline
          ? phoneNumbers.landline
          : undefined,
      oldPhone: null,
      country: account?.country !== country ? country : undefined,
      region:
        account?.region !== parseInt(region as string)
          ? parseInt(region as string)
          : undefined,
      postcode:
        !account?.postcode || account?.postcode.toString() !== postcode
          ? postcode
          : undefined,
      birthyear:
        account?.birthyear !== parseInt(birth_year as string)
          ? parseInt(birth_year as string)
          : undefined,
      gender: account?.gender !== gender ? gender : undefined,
    };

    try {
      await axios.patch(
        `${BASE_URL}/legacy/${COUNTRY_CODE}/shop/account`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${session?.access_token}`,
            "itk-website-uid": process.env.NEXT_PUBLIC_ITK_WEBSITE_UID!,
          },
        }
      );
      setSuccess("Succesfully updated account details!");
      mutateAccount();
    } catch (error) {
      setLoading(false);
      console.error(error);
      setFailure("Oops! We couldn't update your account details.", true);
      if ((error as AxiosError).response?.status === 409) {
        setError((prev) => ({ ...prev, user_exists: true }));
        return;
      }
      setError((prev) => ({ ...prev, other: true }));
    }
    setLoading(false);
  }

  if (accountLoading || !hydrated) {
    return (
      <div className="flex items-center justify-center mt-4">
        <LoadingSpinner size="48" />
      </div>
    );
  }

  if (!account) {
    return (
      <div className="mt-12 sm:mt-8 mx-4 sm:mx-32 flex flex-col items-center justify-center">
        <div className="relative">
          <h3 className="relative z-10 font-black text-4xl text-black text-center">
            Oh no!
          </h3>
          <ExclamationTriangleIcon className="absolute -top-14 sm:-top-16 -left-16 text-red-400 w-24 h-24 -rotate-12" />
        </div>
        <div className="relative">
          <p className="relative z-10 font-bold text-2xl text-black text-center">
            Looks like we couldn&apos;t get your account information, please try
            again later.
          </p>
          <FaceFrownIcon className="absolute -bottom-8 -right-6 sm:-right-[4.25rem] text-[#F9CF47] w-24 h-24 rotate-[15deg]" />
        </div>
      </div>
    );
  }

  return (
    <Form.Root onSubmit={handleSubmit} className="relative flex flex-col gap-4">
      <h2 className="mt-4 text-3xl font-bold text-black">Details</h2>
      <div className="flex flex-col md:grid grid-cols-2 gap-x-8 gap-y-4">
        <Form.Field
          name="first_name"
          className="col-span-1 flex flex-col gap-1"
        >
          <div className="flex items-center gap-2 h-6">
            <Form.Label htmlFor="first_name" className="text-black font-medium">
              First Name
            </Form.Label>
            <Form.Message
              match="valueMissing"
              className="flex items-center gap-0.5 px-2 py-0.5 rounded-full bg-red-100 text-red-800"
            >
              <ExclamationCircleIcon className="h-4 w-4 stroke-2" />
              <span className="text-sm">Required</span>
            </Form.Message>
          </div>
          <Form.Control
            id="first_name"
            className="rounded-md border border-gray-300 px-2 py-1.5 text-black focus:outline-none focus:border-2 focus:py-[5px] focus:px-[7px] focus:border-indigo-500"
            type="text"
            autoComplete="given-name"
            defaultValue={account.firstname}
            required
          />
        </Form.Field>
        <Form.Field name="last_name" className="col-span-1 flex flex-col gap-1">
          <div className="flex items-center gap-2 h-6">
            <Form.Label htmlFor="last_name" className="text-black font-medium">
              Last Name
            </Form.Label>
            <Form.Message
              match="valueMissing"
              className="flex items-center gap-0.5 px-2 py-0.5 rounded-full bg-red-100 text-red-800"
            >
              <ExclamationCircleIcon className="h-4 w-4 stroke-2" />
              <span className="text-sm">Required</span>
            </Form.Message>
          </div>
          <Form.Control
            id="last_name"
            className="rounded-md border border-gray-300 px-2 py-1.5 text-black focus:outline-none focus:border-2 focus:py-[5px] focus:px-[7px] focus:border-indigo-500"
            type="text"
            autoComplete="family-name"
            defaultValue={account.surname}
            required
          />
        </Form.Field>
        <Form.Field
          name="email"
          className="col-span-1 flex flex-col gap-1"
          serverInvalid={error.user_exists}
        >
          <div className="flex items-center gap-2 h-6">
            <Form.Label htmlFor="email" className="text-black font-medium">
              Email Address
            </Form.Label>
            <Form.Message
              match="valueMissing"
              className="flex items-center gap-0.5 px-2 py-0.5 rounded-full bg-red-100 text-red-800"
            >
              <ExclamationCircleIcon className="h-4 w-4 stroke-2" />
              <span className="text-sm">Required</span>
            </Form.Message>
            <Form.Message
              match="typeMismatch"
              className="flex items-center gap-0.5 px-2 py-0.5 rounded-full bg-red-100 text-red-800"
            >
              <ExclamationCircleIcon className="h-4 w-4 stroke-2" />
              <span className="text-sm">Please enter a valid email.</span>
            </Form.Message>
            {error.user_exists && (
              <Form.Message className="flex items-center gap-0.5 px-2 py-0.5 rounded-full bg-red-100 text-red-800">
                <ExclamationCircleIcon className="h-4 w-4 stroke-2" />
                <span className="text-sm">This email is already in use.</span>
              </Form.Message>
            )}
          </div>
          <Form.Control
            id="email"
            className="rounded-md border border-gray-300 px-2 py-1.5 text-black focus:outline-none focus:border-2 focus:py-[5px] focus:px-[7px] focus:border-indigo-500"
            type="email"
            autoComplete="email"
            defaultValue={account.email}
            onChange={() =>
              setError((prev) => ({ ...prev, user_exists: false }))
            }
            required
          />
        </Form.Field>
        <div className="col-span-1 max-md:hidden" />
        <div className="flex items-center gap-2 h-6 col-span-1 md:col-span-2 -mb-3">
          <p className="text-black font-medium">Phone</p>
          {error.missing_phone_numbers ? (
            <div className="flex items-center gap-0.5 px-2 py-0.5 rounded-full bg-red-100 text-red-800">
              <ExclamationCircleIcon className="h-4 w-4 stroke-2" />
              <span className="text-sm">At least 1 required</span>
            </div>
          ) : (
            <></>
          )}
        </div>
        <div className="col-span-1 flex flex-col gap-1">
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-2 h-6">
              <label htmlFor="mobile_input" className="text-black font-medium">
                Mobile
              </label>
              {showOldPhone.mobile && account.oldMobile && !account.mobile ? (
                <PhoneNumberTooltip
                  old_number={account.oldMobile}
                  setPhoneNumber={(phone_number) => {
                    setError((prev) => ({
                      ...prev,
                      missing_phone_numbers: false,
                    }));
                    setPhoneNumbers((prev) => ({
                      ...prev,
                      mobile: phone_number
                        ? parsePhoneNumber(phone_number, defaultCountryCode)
                            ?.number || ""
                        : "",
                    }));
                    setShowOldPhone((prev) => ({ ...prev, mobile: false }));
                  }}
                />
              ) : (
                <></>
              )}
            </div>
          </div>
          <PhoneInputWithCountrySelect
            name="mobile_input"
            id="mobile_input"
            defaultCountry={defaultCountryCode}
            value={phoneNumbers.mobile}
            autoComplete="mobile"
            className="rounded-md border border-gray-300 divide-x pr-2 divide-gray-300 text-black focus-within:ring-1 focus-within:ring-indigo-500 focus-within:border-indigo-500 focus-within:divide-x-2 focus-within:divide-indigo-500"
            onChange={(value) => {
              setError((prev) => ({ ...prev, missing_phone_numbers: false }));
              setPhoneNumbers((prev) => ({ ...prev, mobile: value || "" }));
            }}
          />
        </div>
        <div className="col-span-1 flex flex-col gap-1">
          <div className="flex items-baseline justify-between">
            <div className="flex items-center gap-2 h-6">
              <label
                htmlFor="landline_input"
                className="text-black font-medium"
              >
                Landline
              </label>
              {showOldPhone.phone && account.oldPhone && !account.phone ? (
                <PhoneNumberTooltip
                  old_number={account.oldPhone}
                  setPhoneNumber={(phone_number) => {
                    setError((prev) => ({
                      ...prev,
                      missing_phone_numbers: false,
                    }));
                    setPhoneNumbers((prev) => ({
                      ...prev,
                      landline: phone_number
                        ? parsePhoneNumber(phone_number, defaultCountryCode)
                            ?.number || ""
                        : "" || "",
                    }));
                    setShowOldPhone((prev) => ({ ...prev, phone: false }));
                  }}
                />
              ) : (
                <></>
              )}
            </div>
          </div>
          <PhoneInputWithCountrySelect
            name="landline_input"
            id="landline_input"
            defaultCountry={defaultCountryCode}
            value={phoneNumbers.landline}
            autoComplete="tel"
            className="rounded-md border border-gray-300 divide-x pr-2 divide-gray-300 text-black focus-within:ring-1 focus-within:ring-indigo-500 focus-within:border-indigo-500 focus-within:divide-x-2 focus-within:divide-indigo-500"
            onChange={(value) => {
              setError((prev) => ({ ...prev, missing_phone_numbers: false }));
              setPhoneNumbers((prev) => ({ ...prev, landline: value || "" }));
            }}
          />
        </div>
        <Form.Field name="country" className="col-span-1 flex flex-col gap-1">
          <div className="flex items-center gap-2 h-6">
            <Form.Label htmlFor="country" className="text-black font-medium">
              Country
            </Form.Label>
            <Form.Message
              match="valueMissing"
              className="flex items-center gap-0.5 px-2 py-0.5 rounded-full bg-red-100 text-red-800"
            >
              <ExclamationCircleIcon className="h-4 w-4 stroke-2" />
              <span className="text-sm">Required</span>
            </Form.Message>
          </div>
          <div className="relative w-full">
            <Form.Control id="country" asChild required>
              <select
                className="w-full px-4 text-black bg-white border border-gray-300 rounded shadow-sm cursor-default hover:bg-gray-100 h-9 focus:outline-none focus:ring-1 focus:border-indigo-500 focus:ring-indigo-500 appearance-none transition-colors duration-200 ease-in-out"
                autoComplete="country"
                defaultValue={account.country}
                onChange={(event) => {
                  const newCountry = countries.find(
                    (c) => c.code === event.target.value
                  );

                  if (newCountry) {
                    setSelectedCountry(newCountry);
                  }
                }}
              >
                {countries.map((country) => (
                  <option key={country.code} value={country.code}>
                    {country.name}
                  </option>
                ))}
              </select>
            </Form.Control>
            <ChevronDownIcon className="absolute top-1/2 -translate-y-1/2 right-4 w-5 h-5 text-black pointer-events-none" />
          </div>
        </Form.Field>
        {selectedCountry.regions && selectedCountry.regions.length > 0 ? (
          <Form.Field name="region" className="col-span-1 flex flex-col gap-1">
            <div className="flex items-center gap-2 h-6">
              <Form.Label htmlFor="region" className="text-black font-medium">
                Region
              </Form.Label>
              <Form.Message
                match="valueMissing"
                className="flex items-center gap-0.5 px-2 py-0.5 rounded-full bg-red-100 text-red-800"
              >
                <ExclamationCircleIcon className="h-4 w-4 stroke-2" />
                <span className="text-sm">Required</span>
              </Form.Message>
            </div>
            <div className="relative w-full">
              <Form.Control id="region" asChild required>
                <select
                  autoComplete="on"
                  className="w-full px-4 text-black bg-white border border-gray-300 rounded shadow-sm cursor-default hover:bg-gray-100 h-9 focus:outline-none focus:ring-1 focus:border-indigo-500 focus:ring-indigo-500 appearance-none transition-colors duration-200 ease-in-out"
                  defaultValue={account.region}
                >
                  {selectedCountry.regions.map((region) => (
                    <option key={region.id} value={region.id}>
                      {region.name}
                    </option>
                  ))}
                </select>
              </Form.Control>
              <ChevronDownIcon className="absolute top-1/2 -translate-y-1/2 right-4 w-5 h-5 text-black pointer-events-none" />
            </div>
          </Form.Field>
        ) : (
          <></>
        )}
        <Form.Field name="postcode" className="col-span-1 flex flex-col gap-1">
          <div className="flex items-center gap-2 h-6">
            <Form.Label htmlFor="postcode" className="text-black font-medium">
              Postcode
            </Form.Label>
            <Form.Message
              match="valueMissing"
              className="flex items-center gap-0.5 px-2 py-0.5 rounded-full bg-red-100 text-red-800"
            >
              <ExclamationCircleIcon className="h-4 w-4 stroke-2" />
              <span className="text-sm">Required</span>
            </Form.Message>
          </div>
          <Form.Control
            id="postcode"
            className="w-1/3 min-w-[8rem] rounded-md border border-gray-300 px-2 py-1.5 text-black focus:outline-none focus:border-2 focus:py-[5px] focus:px-[7px] focus:border-indigo-500"
            defaultValue={account.postcode}
            type="text"
            required
            maxLength={15}
          />
        </Form.Field>
      </div>
      <h2 className="mt-4 text-3xl font-bold text-black">Optional</h2>
      <div className="flex flex-col md:grid grid-cols-2 gap-x-8 gap-y-4">
        <Form.Field
          name="birth_year"
          className="col-span-1 flex flex-col gap-1"
        >
          <div className="flex items-center gap-2 h-6">
            <Form.Label className="text-black font-medium">
              Year of birth
            </Form.Label>
            <span className="px-2 py-0.5 rounded-full bg-indigo-100 text-indigo-800 text-sm">
              Optional
            </span>
          </div>
          <div className="relative w-full">
            <Form.Control asChild>
              <select
                className="w-full px-4 text-black bg-white border border-gray-300 rounded shadow-sm cursor-default hover:bg-gray-100 h-9 focus:outline-none focus:ring-2 focus:border-indigo-500 focus:ring-indigo-500 appearance-none transition-colors duration-200 ease-in-out"
                defaultValue={account.birthyear}
              >
                <option value="">Select a year</option>
                {yearsArray.map((year) => (
                  <option key={year} value={year}>
                    {year}
                  </option>
                ))}
              </select>
            </Form.Control>
            <ChevronDownIcon className="absolute top-1/2 -translate-y-1/2 right-4 w-5 h-5 text-black pointer-events-none" />
          </div>
        </Form.Field>
        <Form.Field name="gender" className="col-span-1 flex flex-col gap-2">
          <div className="flex items-center gap-2 h-6">
            <Form.Label className="text-black font-medium">Gender</Form.Label>
            <span className="px-2 py-0.5 rounded-full bg-indigo-100 text-indigo-800 text-sm">
              Optional
            </span>
          </div>
          <Form.Control asChild>
            <fieldset className="flex gap-4 flex-wrap">
              <div className="flex gap-1 items-center">
                <input
                  type="radio"
                  id="radio_male"
                  name="gender"
                  value="m"
                  defaultChecked={account.gender?.toLowerCase() === "m"}
                  className="accent-indigo-500"
                />
                <label htmlFor="radio_male" className="text-black">
                  Male
                </label>
              </div>
              <div className="flex gap-1 items-center">
                <input
                  type="radio"
                  id="radio_female"
                  name="gender"
                  value="f"
                  defaultChecked={account.gender?.toLowerCase() === "f"}
                  className="accent-indigo-500"
                />
                <label htmlFor="radio_female" className="text-black">
                  Female
                </label>
              </div>
              <div className="flex gap-1 items-center">
                <input
                  type="radio"
                  id="radio_diverse"
                  name="gender"
                  value="x"
                  defaultChecked={account.gender?.toLowerCase() === "x"}
                  className="accent-indigo-500"
                />
                <label htmlFor="radio_diverse" className="text-black">
                  Gender Diverse
                </label>
              </div>
            </fieldset>
          </Form.Control>
        </Form.Field>
      </div>
      <Form.Submit
        className="mt-4 col-span-2 w-fit py-2 px-4 rounded-md bg-indigo-500 hover:bg-indigo-400 disabled:bg-indigo-200 disabled:cursor-not-allowed text-white font-semibold transition-colors duration-200 ease-in-out"
        onClick={() => {
          setError((prev) => ({ ...prev, other: false }));
        }}
        disabled={loading || error.user_exists || error.missing_phone_numbers}
      >
        {loading ? (
          <div className="flex justify-center items-center gap-2">
            <LoadingSpinner size="18" color="#ffffff" />
            <span>Saving...</span>
          </div>
        ) : (
          <span>Save</span>
        )}
      </Form.Submit>
      {error.other ? (
        <p className="absolute top-full mt-2 text-sm text-red-500">
          Oops! Something went wrong.
        </p>
      ) : (
        <></>
      )}
    </Form.Root>
  );
}
