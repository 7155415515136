"use client";

import { analytics } from "@components/analytics";
import LoadingSpinner from "@components/loadingSpinner";
import { ExclamationCircleIcon } from "@heroicons/react/24/outline";
import * as Form from "@radix-ui/react-form";
import { useNotificationStore } from "@store/notificationStore";
import { createBrowserClient } from "@supabase/ssr";
import {
  BASE_URL,
  COUNTRY_CODE,
  SUPABASE_ANON_KEY,
  SUPABASE_URL,
} from "@utils/constants";
import axios from "axios";
import { AnimatePresence, motion } from "framer-motion";
import Link from "next/link";
import { useRouter, useSearchParams } from "next/navigation";
import { FormEvent, useState } from "react";
import { OtpForm } from ".";

interface Props {
  submitCallback?: () => Promise<void>;
  next?: string;
}

/**
 * Sign in component for user to authenticate to the website via supabase
 *
 */
export default function SignInForm({ submitCallback, next }: Props) {
  const router = useRouter();
  const searchParams = useSearchParams();
  const redirectTo = searchParams.get("redirectTo");
  const supabase = createBrowserClient(SUPABASE_URL!, SUPABASE_ANON_KEY!);
  const setFailure = useNotificationStore((state) => state.setFailure);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [usingPassword, setUsingPassword] = useState<boolean | null>(null);
  const [otpEmail, setOtpEmail] = useState("");

  const otherParams = searchParams
    .toString()
    .split("&")
    .filter(
      (param) => !param.startsWith("redirectTo") && !param.startsWith("country")
    )
    .join("&");

  async function handleSyncUser(email: string, password: string) {
    try {
      await axios.post(
        `${BASE_URL}/legacy/${COUNTRY_CODE}/shop/account/sync`,
        {
          email,
        },
        {
          headers: {
            "itk-website-uid": process.env.NEXT_PUBLIC_ITK_WEBSITE_UID,
          },
        }
      );

      const { error, data } = await supabase.auth.signInWithPassword({
        email: email,
        password: password,
      });

      if (error) {
        setLoading(false);
        setFailure(error.message);
        setError(true);
        return "failure";
      }

      analytics.identify(
        data?.user?.app_metadata?.iticket_user_uid
          ? data.user.app_metadata.iticket_user_uid.toLowerCase()
          : email,
        {
          id: data?.user?.app_metadata?.iticket_user_uid?.toLowerCase(),
          email,
        }
      );

      analytics.track("Sign in", {
        iticket_uid: data?.user?.app_metadata?.iticket_user_uid?.toLowerCase(),
        email: email,
        method: "password",
      });

      return "success";
    } catch (error) {
      console.error(error);
      await supabase.auth.signOut();
      analytics.reset();
      setLoading(false);
      setFailure("Invalid login details.");
      setError(true);
      return "failure";
    }
  }

  async function handleSubmit(event: FormEvent<HTMLFormElement>) {
    setLoading(true);
    event.preventDefault();
    const { email, password } = Object.fromEntries(
      new FormData(event.currentTarget)
    );

    if (usingPassword === null) {
      setUsingPassword(true);
      setLoading(false);
      return;
    }

    if (!usingPassword) {
      setLoading(false);
      await axios
        .post(
          `${BASE_URL}/legacy/${COUNTRY_CODE}/shop/account/sync`,
          {
            email,
          },
          {
            headers: {
              "itk-website-uid": process.env.NEXT_PUBLIC_ITK_WEBSITE_UID,
            },
          }
        )
        .then((data) => data)
        .catch((err) => err);

      const { error } = await supabase.auth.signInWithOtp({
        email: email as string,
        options: {
          shouldCreateUser: false,
        },
      });

      if (error) {
        setFailure("Invalid login details");
        setError(true);
        return;
      }
      setOtpEmail(email as string);
      return;
    }

    const { error, data } = await supabase.auth.signInWithPassword({
      email: email as string,
      password: password as string,
    });

    if (error) {
      const status = await handleSyncUser(email as string, password as string);

      if (status === "failure") return;
    }

    if (!error && !data.user?.app_metadata["iticket_user_uid"]) {
      await supabase.auth.signOut();
      analytics.reset();
      setLoading(false);
      setFailure("Invalid login details");
      setError(true);
      return;
    }

    if (data.user) {
      analytics.identify(
        data?.user?.app_metadata?.iticket_user_uid
          ? data.user.app_metadata.iticket_user_uid.toLowerCase()
          : (email as string),
        {
          id: data?.user?.app_metadata?.iticket_user_uid?.toLowerCase(),
          email: email as string,
        }
      );

      analytics.track("Sign in", {
        iticket_uid: data?.user?.app_metadata?.iticket_user_uid?.toLowerCase(),
        email: email,
        method: "password",
      });
    }

    // callback func
    if (submitCallback) {
      await submitCallback();
    }

    if (redirectTo) {
      router.replace(`${redirectTo}?${otherParams}`);
    }
    router.refresh();
  }

  if (otpEmail) {
    return (
      <OtpForm email={otpEmail} submitCallback={submitCallback} next={next} />
    );
  }

  return (
    <Form.Root
      onSubmit={handleSubmit}
      onClearServerErrors={() => setError(false)}
    >
      <Form.Field
        name="email"
        className="flex flex-col gap-1 mb-4"
        serverInvalid={error}
      >
        <div className="flex items-center justify-between h-6">
          <Form.Label htmlFor="email" className="text-black font-medium">
            Email address
          </Form.Label>
          <Form.Message
            match="valueMissing"
            className="flex items-center gap-0.5 px-2 py-0.5 rounded-full bg-red-100 text-red-800"
          >
            <ExclamationCircleIcon className="h-4 w-4 stroke-2" />
            <span className="text-sm">Required</span>
          </Form.Message>
          <Form.Message
            match="typeMismatch"
            className="flex items-center gap-0.5 px-2 py-0.5 rounded-full bg-red-100 text-red-800"
          >
            <ExclamationCircleIcon className="h-4 w-4 stroke-2" />
            <span className="text-sm">Invalid email</span>
          </Form.Message>
          {error && (
            <div className="flex items-center gap-0.5 px-2 py-0.5 rounded-full bg-red-100 text-red-800">
              <ExclamationCircleIcon className="h-4 w-4 stroke-2" />
              <span className="text-sm">Invalid login details</span>
            </div>
          )}
        </div>
        <Form.Control
          className="rounded-md border border-gray-300 px-2 py-1.5 text-black focus:outline-none focus:border-2 focus:py-[5px] focus:px-[7px] focus:border-indigo-500"
          id="email"
          name="email"
          autoComplete="email"
          data-testid="email"
          type="email"
          onChange={() => setError(false)}
          required
        />
      </Form.Field>
      <AnimatePresence>
        {!!usingPassword && (
          <motion.div
            initial={{ opacity: 0, height: 0, marginBottom: 0 }}
            animate={{
              opacity: 1,
              height: "auto",
              marginBottom: 24,
              transition: { opacity: { delay: 0.2 } },
            }}
            exit={{
              opacity: 0,
              height: 0,
              marginBottom: 0,
              transition: {
                height: { delay: 0.2 },
                marginBottom: { delay: 0.2 },
              },
            }}
          >
            <Form.Field name="password" className="flex flex-col gap-1">
              <div className="flex items-center justify-between h-6">
                <Form.Label
                  htmlFor="password"
                  className="text-black font-medium"
                >
                  Password
                </Form.Label>
                <Form.Message
                  match="valueMissing"
                  className="flex items-center gap-0.5 px-2 py-0.5 rounded-full bg-red-100 text-red-800"
                >
                  <ExclamationCircleIcon className="h-4 w-4 stroke-2" />
                  <span className="text-sm">Required</span>
                </Form.Message>
              </div>
              <Form.Control
                className="rounded-md border border-gray-300 px-2 py-1.5 text-black focus:outline-none focus:border-2 focus:py-[5px] focus:px-[7px] focus:border-indigo-500"
                id="password"
                name="password"
                data-testid="password"
                type="password"
                required
              />
            </Form.Field>
            <div className="mt-4">
              <Link
                href="/account/reset-password"
                className="text-indigo-500 font-medium"
              >
                Forgot your password?
              </Link>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
      {!usingPassword && (
        <div className="flex flex-col gap-6">
          <Form.Submit
            onMouseDown={() => setUsingPassword(null)}
            className="w-full p-2 rounded-md bg-indigo-500 hover:bg-indigo-400 disabled:bg-indigo-200 disabled:cursor-not-allowed text-white font-semibold transition-colors duration-200 ease-in-out"
          >
            Log in with your iTICKET password
          </Form.Submit>
          <div className="relative w-full border-t">
            <span className="absolute -top-0.5 -translate-y-1/2 left-1/2 -translate-x-1/2 bg-white text-gray-500 px-2">
              or
            </span>
          </div>
          <div className="flex flex-col gap-4">
            <Form.Submit
              onMouseDown={() => setUsingPassword(false)}
              disabled={loading}
              className="w-full p-2 rounded-md bg-indigo-100 hover:bg-indigo-500 disabled:bg-indigo-200 disabled:cursor-not-allowed text-indigo-800 hover:text-white font-semibold transition-colors duration-200 ease-in-out"
            >
              {loading ? (
                <div className="flex justify-center items-center gap-2">
                  <LoadingSpinner size="18" color="#ffffff" />
                  <span>Sending one time password...</span>
                </div>
              ) : (
                <span>Request a one time password</span>
              )}
            </Form.Submit>
            <p className="text-gray-500 text-sm">
              If you have a registered iTICKET account, you can use this option.
              We will email you a six digit code that you can use to log in. If
              you aren&apos;t registered with iTICKET, you will not receive an
              email from us and you need to sign up using the link above.
            </p>
          </div>
        </div>
      )}
      {!!usingPassword && (
        <div className="flex flex-col gap-4">
          <Form.Submit
            data-testid="submit"
            className="w-full p-2 rounded-md bg-indigo-500 hover:bg-indigo-400 disabled:bg-indigo-200 disabled:cursor-not-allowed text-white font-semibold transition-colors duration-200 ease-in-out"
            disabled={loading}
          >
            {loading ? (
              <div className="flex justify-center items-center gap-2">
                <LoadingSpinner size="18" color="#ffffff" />
                <span>Logging in...</span>
              </div>
            ) : (
              <span>Log in</span>
            )}
          </Form.Submit>
          <button
            onClick={() => {
              setUsingPassword(null);
            }}
            className="w-full p-2 rounded-md bg-indigo-100 hover:bg-indigo-500 disabled:bg-indigo-200 disabled:cursor-not-allowed text-indigo-800 hover:text-white font-semibold transition-colors duration-200 ease-in-out"
          >
            Go back
          </button>
        </div>
      )}
    </Form.Root>
  );
}
