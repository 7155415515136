"use client";

import {
  ChevronDownIcon,
  ExclamationCircleIcon,
  EyeIcon,
  EyeSlashIcon,
  PlusIcon,
} from "@heroicons/react/24/outline";
import { Turnstile } from "@marsidev/react-turnstile";
import * as Form from "@radix-ui/react-form";
import { Country } from "@types";
import { range } from "@utils/helpers";
import axios, { AxiosError } from "axios";
import { useRouter, useSearchParams } from "next/navigation";
import { FormEvent, useRef, useState } from "react";
import PhoneInputWithCountrySelect from "react-phone-number-input";
import { CountryCode } from "libphonenumber-js/core";
import "react-phone-number-input/style.css";
import Link from "next/link";
import { analytics } from "@components/analytics";
import LoadingSpinner from "@components/loadingSpinner";
import { AnimatePresence, motion } from "framer-motion";
import { useIsInViewport, useWindowSize } from "@utils/hooks";
import {
  BASE_URL,
  COUNTRY_CODE,
  SUPABASE_ANON_KEY,
  SUPABASE_URL,
} from "@utils/constants";
import { createBrowserClient } from "@supabase/ssr";

interface Props {
  countries: Country[];
  defaultCountryCode: string;
}

export default function SignUpForm({ countries, defaultCountryCode }: Props) {
  const router = useRouter();
  const supabase = createBrowserClient(SUPABASE_URL!, SUPABASE_ANON_KEY!);
  const searchParams = useSearchParams();
  const redirectTo = searchParams.get("redirectTo");
  const { targetRef, scrollToTarget } = useIsInViewport<HTMLParagraphElement>();
  const windowSize = useWindowSize();
  const turnstileRef = useRef<any>();
  const [loading, setLoading] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState<Country>(countries[0]);
  const [error, setError] = useState({
    captcha: false,
    missing_phone_numbers: false,
    user_exists: false,
    other: false,
  });
  const [phoneNumbers, setPhoneNumbers] = useState<{
    mobile?: string;
    landline?: string;
  }>({
    mobile: undefined,
    landline: undefined,
  });
  const [lastPhoneNumbers, setLastPhoneNumbers] = useState(phoneNumbers);
  const [passwordShowing, setPasswordShowing] = useState(false);
  const [captchaToken, setCaptchaToken] = useState<string>();

  const yearsArray = range(
    new Date().getFullYear() - 10,
    new Date().getFullYear() - 100,
    -1
  );

  async function handleSubmit(event: FormEvent<HTMLFormElement>) {
    setLoading(true);
    event.preventDefault();
    const {
      first_name,
      surname,
      password,
      email,
      country,
      region,
      postcode,
      news,
      birth_year,
      gender,
      terms_and_conditions,
    } = Object.fromEntries(new FormData(event.currentTarget));

    if (!phoneNumbers.mobile && !phoneNumbers.landline) {
      setError((prev) => ({ ...prev, missing_phone_numbers: true }));
      setLoading(false);
      scrollToTarget("start");
      return;
    }

    try {
      await axios.post(
        `${BASE_URL}/legacy/${COUNTRY_CODE}/shop/account/signup`,
        {
          firstname: first_name,
          surname,
          password,
          email,
          mobile: phoneNumbers.mobile || undefined,
          phone: phoneNumbers.landline || undefined,
          country,
          region: region ? parseInt(region as string) : undefined,
          postcode,
          emailUpdates: news === "yes",
          birthyear: birth_year ? parseInt(birth_year as string) : undefined,
          gender,
          terms: terms_and_conditions === "yes",
          captchaToken,
        },
        {
          headers: {
            "itk-website-uid": process.env.NEXT_PUBLIC_ITK_WEBSITE_UID,
          },
        }
      );

      const { data, error } = await supabase.auth.signInWithPassword({
        email: email as string,
        password: password as string,
      });

      if (error) {
        setLoading(false);
        setError((prev) => ({ ...prev, other: true }));
        turnstileRef.current.reset();
        return;
      }

      analytics.identify(
        data?.user?.app_metadata?.iticket_user_uid
          ? data.user.app_metadata.iticket_user_uid.toLowerCase()
          : (email as string),
        {
          id: data?.user?.app_metadata?.iticket_user_uid?.toLowerCase(),
          email: email as string,
          firstname: first_name.toString(),
          lastname: surname.toString(),
        }
      );

      analytics.track("Sign Up", {
        iticket_uid: data?.user?.app_metadata?.iticket_user_uid?.toLowerCase(),
        email: email,
      });

      if (redirectTo) {
        router.replace(redirectTo);
      }

      router.replace(`/account/welcome?${searchParams}`);
    } catch (error) {
      console.error(error);
      setLoading(false);
      turnstileRef.current.reset();
      if ((error as AxiosError).response?.status === 409) {
        setError((prev) => ({ ...prev, user_exists: true }));
        return;
      }
      if (
        ((error as AxiosError).response?.data as string).includes("Captcha")
      ) {
        setError((prev) => ({ ...prev, captcha: true }));
        return;
      }
      setError((prev) => ({ ...prev, other: true }));
    }
  }

  return (
    <Form.Root onSubmit={handleSubmit}>
      <div>
        <h2 className="text-3xl font-bold text-black">Details</h2>
        <Form.Field name="first_name" className="mt-4 flex flex-col gap-1">
          <div className="flex items-center gap-2 h-6">
            <Form.Label htmlFor="first_name" className="text-black font-medium">
              First Name
            </Form.Label>
            <Form.Message
              match="valueMissing"
              className="flex items-center gap-0.5 px-2 py-0.5 rounded-full bg-red-100 text-red-800"
            >
              <ExclamationCircleIcon className="h-4 w-4 stroke-2" />
              <span className="text-sm">Required</span>
            </Form.Message>
          </div>
          <Form.Control
            className="rounded-md border border-gray-300 px-2 py-1.5 text-black focus:outline-none focus:border-2 focus:py-[5px] focus:px-[7px] focus:border-indigo-500"
            id="first_name"
            name="first_name"
            type="text"
            autoComplete="given-name"
            required
          />
        </Form.Field>
        <Form.Field name="surname" className="mt-4 flex flex-col gap-1">
          <div className="flex items-center gap-2 h-6">
            <Form.Label htmlFor="surname" className="text-black font-medium">
              Last Name
            </Form.Label>
            <Form.Message
              match="valueMissing"
              className="flex items-center gap-0.5 px-2 py-0.5 rounded-full bg-red-100 text-red-800"
            >
              <ExclamationCircleIcon className="h-4 w-4 stroke-2" />
              <span className="text-sm">Required</span>
            </Form.Message>
          </div>
          <Form.Control
            className="rounded-md border border-gray-300 px-2 py-1.5 text-black focus:outline-none focus:border-2 focus:py-[5px] focus:px-[7px] focus:border-indigo-500"
            id="surname"
            name="surname"
            type="text"
            autoComplete="family-name"
            required
          />
        </Form.Field>
        <Form.Field
          name="email"
          className="mt-4 flex flex-col gap-1"
          serverInvalid={error.user_exists}
        >
          <div className="flex items-center gap-2 h-6">
            <Form.Label htmlFor="email" className="text-black font-medium">
              Email Address
            </Form.Label>
            <Form.Message
              match="valueMissing"
              className="flex items-center gap-0.5 px-2 py-0.5 rounded-full bg-red-100 text-red-800"
            >
              <ExclamationCircleIcon className="h-4 w-4 stroke-2" />
              <span className="text-sm">Required</span>
            </Form.Message>
            <Form.Message
              match="typeMismatch"
              className="flex items-center gap-0.5 px-2 py-0.5 rounded-full bg-red-100 text-red-800"
            >
              <ExclamationCircleIcon className="h-4 w-4 stroke-2" />
              <span className="text-sm">Invalid email</span>
            </Form.Message>
            {error.user_exists && (
              <div className="flex items-center gap-0.5 px-2 py-0.5 rounded-full bg-red-100 text-red-800">
                <ExclamationCircleIcon className="h-4 w-4 stroke-2" />
                <span className="text-sm">This email is already in use.</span>
              </div>
            )}
          </div>
          <Form.Control
            className="rounded-md border border-gray-300 px-2 py-1.5 text-black focus:outline-none focus:border-2 focus:py-[5px] focus:px-[7px] focus:border-indigo-500"
            id="email"
            name="email"
            type="email"
            autoComplete="email"
            required
            onChange={() =>
              setError((prev) => ({ ...prev, user_exists: false }))
            }
          />
        </Form.Field>
        <Form.Field name="password" className="mt-4 flex flex-col gap-1">
          <div className="flex items-center gap-2 h-6">
            <Form.Label htmlFor="password" className="text-black font-medium">
              Password
            </Form.Label>
            <Form.Message
              match="valueMissing"
              className="flex items-center gap-0.5 px-2 py-0.5 rounded-full bg-red-100 text-red-800"
            >
              <ExclamationCircleIcon className="h-4 w-4 stroke-2" />
              <span className="text-sm">Required</span>
            </Form.Message>
            <Form.Message
              match="tooShort"
              className="flex items-center gap-0.5 px-2 py-0.5 rounded-full bg-red-100 text-red-800"
            >
              <ExclamationCircleIcon className="h-4 w-4 stroke-2" />
              <span className="text-sm">Too short</span>
            </Form.Message>
          </div>
          <div className="relative w-full">
            <Form.Control
              className="w-full rounded-md border border-gray-300 px-2 py-1.5 text-black focus:outline-none focus:border-2 focus:py-[5px] focus:px-[7px] focus:border-indigo-500"
              id="password"
              name="password"
              autoComplete="current-password"
              type={passwordShowing ? "text" : "password"}
              minLength={6}
              required
            />
            <button
              type="button"
              onClick={(e) => e.preventDefault()}
              onPointerDown={() => setPasswordShowing(true)}
              onPointerUp={() => setPasswordShowing(false)}
              onPointerLeave={() => setPasswordShowing(false)}
              className="absolute inset-y-0 right-0 px-3 rounded-r focus-visible:outline-none"
            >
              {passwordShowing ? (
                <EyeIcon className="w-5 h-5 text-black" />
              ) : (
                <EyeSlashIcon className="w-5 h-5 text-black" />
              )}
            </button>
          </div>
        </Form.Field>
        <div className="mt-4 flex flex-col gap-1">
          <div className="flex items-center gap-2 h-6">
            <p ref={targetRef} className="text-black font-medium scroll-m-56">
              Phone
            </p>
            {error.missing_phone_numbers && (
              <div className="flex items-center gap-0.5 px-2 py-0.5 rounded-full bg-red-100 text-red-800">
                <ExclamationCircleIcon className="h-4 w-4 stroke-2" />
                <span className="text-sm">At least 1 required</span>
              </div>
            )}
          </div>
          <div className="ml-2 flex flex-col gap-1">
            <label
              htmlFor={
                phoneNumbers.mobile?.length !== undefined
                  ? "mobile_input"
                  : undefined
              }
              className="text-black font-medium"
            >
              Mobile
            </label>
            <AnimatePresence mode="wait">
              <motion.div
                key={
                  phoneNumbers.mobile?.length !== undefined ? "input" : "button"
                }
                initial={{
                  opacity: phoneNumbers.mobile?.length !== undefined ? 0 : 1,
                }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.075 }}
                className="w-full"
              >
                {phoneNumbers.mobile?.length !== undefined ? (
                  <PhoneInputWithCountrySelect
                    id="mobile_input"
                    name="mobile_input"
                    defaultCountry={defaultCountryCode as CountryCode}
                    autoComplete="mobile"
                    className="rounded-md border border-gray-300 divide-x pr-2 divide-gray-300 text-black focus-within:ring-1 focus-within:ring-indigo-500 focus-within:border-indigo-500 focus-within:divide-x-2 focus-within:divide-indigo-500"
                    onChange={(value) => {
                      if (value) {
                        setError((prev) => ({
                          ...prev,
                          missing_phone_numbers: false,
                        }));
                      }
                      setPhoneNumbers((prev) => ({
                        ...prev,
                        mobile: value || "",
                      }));
                    }}
                    onBlur={(event) => {
                      if (
                        !(event.target as HTMLInputElement).value &&
                        !phoneNumbers.landline &&
                        !!lastPhoneNumbers.mobile
                      ) {
                        setError((prev) => ({
                          ...prev,
                          missing_phone_numbers: true,
                        }));
                      }
                      setLastPhoneNumbers((prev) => ({
                        ...prev,
                        mobile: (event.target as HTMLInputElement).value,
                      }));
                    }}
                  />
                ) : (
                  <button
                    className="flex items-center justify-center h-[38px] rounded-md bg-indigo-100 text-indigo-800 w-full"
                    onClick={(e) => {
                      e.preventDefault();
                      setPhoneNumbers((prev) => ({ ...prev, mobile: "" }));
                    }}
                  >
                    <PlusIcon className="h-4 w-4 stroke-2" />
                    <span className="font-semibold">Add a mobile number</span>
                  </button>
                )}
              </motion.div>
            </AnimatePresence>
          </div>
          <div className="ml-2 flex flex-col gap-1">
            <label
              htmlFor={
                phoneNumbers.landline?.length !== undefined
                  ? "landline_input"
                  : undefined
              }
              className="text-black font-medium"
            >
              Landline
            </label>
            <AnimatePresence mode="wait">
              <motion.div
                key={
                  phoneNumbers.landline?.length !== undefined
                    ? "input"
                    : "button"
                }
                initial={{
                  opacity: phoneNumbers.landline?.length !== undefined ? 0 : 1,
                }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.075 }}
                className="w-full"
              >
                {phoneNumbers.landline?.length !== undefined ? (
                  <PhoneInputWithCountrySelect
                    id="landline_input"
                    name="landline_input"
                    defaultCountry={defaultCountryCode as CountryCode}
                    autoComplete="tel"
                    className="rounded-md border border-gray-300 divide-x pr-2 divide-gray-300 text-black focus-within:ring-1 focus-within:ring-indigo-500 focus-within:border-indigo-500 focus-within:divide-x-2 focus-within:divide-indigo-500"
                    onChange={(value) => {
                      if (value) {
                        setError((prev) => ({
                          ...prev,
                          missing_phone_numbers: false,
                        }));
                      }
                      setPhoneNumbers((prev) => ({
                        ...prev,
                        landline: value || "",
                      }));
                    }}
                    onBlur={(event) => {
                      if (
                        !(event.target as HTMLInputElement).value &&
                        !phoneNumbers.mobile &&
                        !!lastPhoneNumbers.landline
                      ) {
                        setError((prev) => ({
                          ...prev,
                          missing_phone_numbers: true,
                        }));
                      }
                      setLastPhoneNumbers((prev) => ({
                        ...prev,
                        landline: (event.target as HTMLInputElement).value,
                      }));
                    }}
                  />
                ) : (
                  <button
                    className="flex items-center justify-center h-[38px] rounded-md bg-indigo-100 text-indigo-800 w-full"
                    onClick={(e) => {
                      e.preventDefault();
                      setPhoneNumbers((prev) => ({ ...prev, landline: "" }));
                    }}
                  >
                    <PlusIcon className="h-4 w-4 stroke-2" />
                    <span className="font-semibold">Add a landline number</span>
                  </button>
                )}
              </motion.div>
            </AnimatePresence>
          </div>
        </div>
        <Form.Field name="country" className="mt-4 flex flex-col gap-1">
          <div className="flex items-center gap-2 h-6">
            <Form.Label htmlFor="country" className="text-black font-medium">
              Country
            </Form.Label>
            <Form.Message
              match="valueMissing"
              className="flex items-center gap-0.5 px-2 py-0.5 rounded-full bg-red-100 text-red-800"
            >
              <ExclamationCircleIcon className="h-4 w-4 stroke-2" />
              <span className="text-sm">Required</span>
            </Form.Message>
          </div>
          <div className="relative w-full">
            <Form.Control id="country" asChild required>
              <select
                className="w-full px-4 text-black bg-white border border-gray-300 rounded shadow-sm cursor-default hover:bg-gray-100 h-9 focus:outline-none focus:ring-2 focus:ring-indigo-500 appearance-none transition-colors duration-200 ease-in-out"
                autoComplete="country"
                onChange={(event) => {
                  const newCountry = countries.find(
                    (c) => c.code === event.target.value
                  );

                  if (newCountry) {
                    setSelectedCountry(newCountry);
                  }
                }}
              >
                {countries.map((country) => (
                  <option key={country.code} value={country.code}>
                    {country.name}
                  </option>
                ))}
              </select>
            </Form.Control>
            <ChevronDownIcon className="absolute top-1/2 -translate-y-1/2 right-4 w-5 h-5 text-black pointer-events-none" />
          </div>
        </Form.Field>
        {selectedCountry.regions && selectedCountry.regions.length > 0 ? (
          <Form.Field name="region" className="mt-4 flex flex-col gap-1">
            <div className="flex items-center gap-2 h-6">
              <Form.Label htmlFor="region" className="text-black font-medium">
                Region
              </Form.Label>
              <Form.Message
                match="valueMissing"
                className="flex items-center gap-0.5 px-2 py-0.5 rounded-full bg-red-100 text-red-800"
              >
                <ExclamationCircleIcon className="h-4 w-4 stroke-2" />
                <span className="text-sm">Required</span>
              </Form.Message>
            </div>
            <div className="relative w-full">
              <Form.Control id="region" asChild required>
                <select
                  autoComplete="on"
                  className="w-full px-4 text-black bg-white border border-gray-300 rounded shadow-sm cursor-default hover:bg-gray-100 h-9 focus:outline-none focus:ring-2 focus:ring-indigo-500 appearance-none transition-colors duration-200 ease-in-out"
                >
                  <option value="">Select a region</option>
                  {selectedCountry.regions.map((region) => (
                    <option key={region.id} value={region.id}>
                      {region.name}
                    </option>
                  ))}
                </select>
              </Form.Control>
              <ChevronDownIcon className="absolute top-1/2 -translate-y-1/2 right-4 w-5 h-5 text-black pointer-events-none" />
            </div>
          </Form.Field>
        ) : (
          <></>
        )}
        <Form.Field name="postcode" className="mt-4 flex flex-col gap-1">
          <div className="flex items-center gap-2 h-6">
            <Form.Label htmlFor="postcode" className="text-black font-medium">
              Postcode
            </Form.Label>
            <Form.Message
              match="valueMissing"
              className="flex items-center gap-0.5 px-2 py-0.5 rounded-full bg-red-100 text-red-800"
            >
              <ExclamationCircleIcon className="h-4 w-4 stroke-2" />
              <span className="text-sm">Required</span>
            </Form.Message>
            <Form.Message
              match="tooLong"
              className="flex items-center gap-0.5 px-2 py-0.5 rounded-full bg-red-100 text-red-800"
            >
              <ExclamationCircleIcon className="h-4 w-4 stroke-2" />
              <span className="text-sm">Too long</span>
            </Form.Message>
          </div>
          <Form.Control
            className="w-1/3 min-w-[8rem] rounded-md border border-gray-300 px-2 py-1.5 text-black focus:outline-none focus:border-2 focus:py-[5px] focus:px-[7px] focus:border-indigo-500"
            id="postcode"
            name="postcode"
            autoComplete="postal-code"
            type="text"
            required
            maxLength={15}
          />
        </Form.Field>
      </div>
      <div className="mt-12">
        <h2 className="text-3xl font-bold text-black">Optional</h2>
        <Form.Field name="news" className="mt-4 flex flex-col gap-1">
          <p className="text-black font-medium">
            Receive news on upcoming local events
          </p>
          <Form.Control asChild>
            <fieldset className="flex gap-4">
              <div className="flex gap-1 items-center">
                <input
                  type="radio"
                  id="radio_yes"
                  name="news"
                  value="yes"
                  className="accent-indigo-500"
                />
                <label htmlFor="radio_yes" className="text-black">
                  Yes
                </label>
              </div>
              <div className="flex gap-1 items-center">
                <input
                  type="radio"
                  id="radio_no"
                  name="news"
                  value="no"
                  className="accent-indigo-500"
                />
                <label htmlFor="radio_no" className="text-black">
                  No
                </label>
              </div>
            </fieldset>
          </Form.Control>
          <p className="text-sm text-indigo-400">
            We&apos;ll add you to a list based on the region you selected above.
            You can manage your subscriptions from your My Account area at any
            time.
          </p>
          <Link
            href="/legal/privacy"
            className="w-fit text-sm text-blue-500 visited:text-purple-500"
            target="_blank"
          >
            Click here for our Privacy Policy.
          </Link>
        </Form.Field>
        <Form.Field name="birth_year" className="mt-4 flex flex-col gap-1">
          <div className="flex items-center gap-2 h-6">
            <p className="text-black font-medium">Year of birth</p>
            <span className="px-2 py-0.5 rounded-full bg-indigo-100 text-indigo-800 text-sm">
              Optional
            </span>
          </div>
          <div className="relative w-full">
            <Form.Control asChild>
              <select className="w-full px-4 text-black bg-white border border-gray-300 rounded shadow-sm cursor-default hover:bg-gray-100 h-9 focus:outline-none focus:ring-2 focus:ring-indigo-500 appearance-none transition-colors duration-200 ease-in-out">
                <option value="">Select a year</option>
                {yearsArray.map((year) => (
                  <option key={year} value={year}>
                    {year}
                  </option>
                ))}
              </select>
            </Form.Control>
            <ChevronDownIcon className="absolute top-1/2 -translate-y-1/2 right-4 w-5 h-5 text-black pointer-events-none" />
          </div>
        </Form.Field>
        <Form.Field name="gender" className="mt-4 flex flex-col gap-1">
          <div className="flex items-center gap-2 h-6">
            <p className="text-black font-medium">Gender</p>
            <span className="px-2 py-0.5 rounded-full bg-indigo-100 text-indigo-800 text-sm">
              Optional
            </span>
          </div>
          <Form.Control asChild>
            <fieldset className="flex gap-4">
              <div className="flex gap-1 items-center">
                <input
                  type="radio"
                  id="radio_male"
                  name="gender"
                  value="m"
                  className="accent-indigo-500"
                />
                <label
                  htmlFor="radio_male"
                  className="text-black max-sm:text-sm"
                >
                  Male
                </label>
              </div>
              <div className="flex gap-1 items-center">
                <input
                  type="radio"
                  id="radio_female"
                  name="gender"
                  value="f"
                  className="accent-indigo-500"
                />
                <label
                  htmlFor="radio_female"
                  className="text-black max-sm:text-sm"
                >
                  Female
                </label>
              </div>
              <div className="flex gap-1 items-center">
                <input
                  type="radio"
                  id="radio_diverse"
                  name="gender"
                  value="x"
                  className="accent-indigo-500"
                />
                <label
                  htmlFor="radio_diverse"
                  className="text-black max-sm:text-sm"
                >
                  Gender Diverse
                </label>
              </div>
            </fieldset>
          </Form.Control>
        </Form.Field>
      </div>
      <Form.Field
        name="terms_and_conditions"
        className="mt-12 flex flex-col gap-1"
      >
        <div className="flex items-center gap-2 h-6">
          <p className="text-black font-medium">Terms and Conditions</p>
          <Form.Message
            match="valueMissing"
            className="flex items-center gap-0.5 px-2 py-0.5 rounded-full bg-red-100 text-red-800"
          >
            <ExclamationCircleIcon className="h-4 w-4 stroke-2" />
            <span className="text-sm">Required</span>
          </Form.Message>
        </div>
        <div className="relative flex gap-1 items-start">
          <Form.Control
            id="checkbox_tac"
            type="checkbox"
            required
            className="mt-1 sm:mt-1.5 accent-indigo-500 flex-shrink-0"
            value={"yes"}
          />
          <label htmlFor="checkbox_tac" className="text-black">
            I have read and agree to the{" "}
            <Link
              href="/legal/terms"
              className="text-blue-500 visited:text-purple-500"
              target="_blank"
            >
              iTICKET Terms & Conditions.
            </Link>
          </label>
        </div>
      </Form.Field>
      <div className="mt-12">
        <p className="text-black font-medium">Captcha</p>
        <Turnstile
          ref={turnstileRef}
          siteKey={process.env.NEXT_PUBLIC_TURNSTILE_SITE_KEY || ""}
          onSuccess={(token) => setCaptchaToken(token)}
          options={{
            size:
              windowSize.width && windowSize.width >= 396
                ? "normal"
                : "compact",
            theme: "light",
          }}
          className="mt-1"
        />
      </div>
      <div className="relative">
        <Form.Submit
          className="mt-12 w-full p-2 rounded-md bg-indigo-500 hover:bg-indigo-400 disabled:bg-indigo-200 disabled:cursor-not-allowed text-white font-semibold transition-colors duration-200 ease-in-out"
          onClick={() => {
            setError((prev) => ({ ...prev, other: false, captcha: false }));
            if (!phoneNumbers.mobile && !phoneNumbers.landline) {
              setError((prev) => ({ ...prev, missing_phone_numbers: true }));
              setLoading(false);
              scrollToTarget("start");
            }
          }}
          disabled={loading || !captchaToken}
        >
          {loading ? (
            <div className="flex justify-center items-center gap-2">
              <LoadingSpinner size="18" color="#ffffff" />
              <span>Signing up...</span>
            </div>
          ) : (
            <span>Sign up</span>
          )}
        </Form.Submit>
        {error.other ? (
          <p className="absolute top-full mt-2 text-sm text-red-500">
            Oops! Something went wrong.
          </p>
        ) : error.captcha ? (
          <p className="absolute top-full mt-2 text-sm text-red-500">
            Oops! The captcha failed to validate. Please try again.
          </p>
        ) : (
          <></>
        )}
      </div>
    </Form.Root>
  );
}
